import React from 'react';
import slugify from 'slugify';
import { Container, Content, Image, Label, Title } from './articleCard.css';
import { getStyleForTag } from '../../hero/hero.helpers';
import { useMemo } from 'react';

export function ArticleCardComponent({ article }) {
  const tagStyles = getStyleForTag(article.brandedTag, article.relatedTag);
  const slug = useMemo(
    () =>
      slugify(article.title, {
        lower: true,
        strict: true,
      }),
    [article]
  );
  return (
    <Container to={`/article/${slug}`}>
      {article.coverPhoto && <Image fluid={article.coverPhoto.fluid} />}
      <Content>
        <Label color={tagStyles.color} light={tagStyles.light}>
          {tagStyles.label}
        </Label>
        <Title>{article.title}</Title>
      </Content>
    </Container>
  );
}
