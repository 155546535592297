import styled from 'styled-components';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { TriangleTag } from '../../../tag/index.css';
import { color } from '../../../../constants/theme';
import MEDIA from '../../../../helpers/mediaTemplates';

export const Container = styled(Link)`
  overflow: hidden;
  box-shadow: 0px 3px 40px -11px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #fcfbf8;
  width: 297px;
  text-decoration: none;
  margin-bottom: 24px;
`;

export const Content = styled.div`
  padding: 20px;
`;

export const Image = styled(Img)`
  height: 176px;
  object-fit: contain;
`;

export const Title = styled.h5`
  font-size: 20px;
  line-height: 28px;
`;

export const Label = styled(TriangleTag)`
  background: ${props => props.color};
  color: ${props => (props.light ? color.white : color.black)};
  margin-bottom: 10px;
`;
