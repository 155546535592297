import React from 'react';
import { Container, Title, Articles, Separator } from './related.css';
import { ContentWrapper } from '../../../global.css';
import { ArticleCard } from './articleCard';

export function ArticleRelatedComponent({ relatedArticles }) {
  return (
    <ContentWrapper>
      <Container>
        <Separator />
        {relatedArticles && (
          <>
            <Title>Related Articles</Title>
            <Articles>
              {relatedArticles.map(article => (
                <ArticleCard key={article.id} article={article} />
              ))}
            </Articles>
          </>
        )}
      </Container>
    </ContentWrapper>
  );
}
