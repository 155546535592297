import { color } from '../../../constants/theme';

export const BrandedTag = {
  THE_READERSHIP: 'THE READERSHIP',
  COMMUNITY_IMPACT: 'COMMUNITY IMPACT',
  FROM_THE_CLUB: 'FROM THE CLUB',
};

const getStyleForRelatedTag = tag => ({
  color: color.mainCollection,
  label: tag,
  light: false,
});

const getStyleForBrandedTag = tag => {
  switch (tag) {
    case BrandedTag.COMMUNITY_IMPACT:
      return {
        color: color.communityPrimary,
        label: tag,
        light: true,
      };
    case BrandedTag.FROM_THE_CLUB:
      return {
        color: color.fromTheClub,
        label: tag,
        light: true,
      };
    case BrandedTag.THE_READERSHIP:
      return {
        color: color.readership,
        label: tag,
        light: true,
      };

    default:
      return getStyleForRelatedTag(tag);
  }
};

export const getStyleForTag = (brandedTag, relatedTag) => {
  if (!brandedTag && !!relatedTag) {
    return getStyleForRelatedTag(relatedTag);
  }
  if (brandedTag) {
    return getStyleForBrandedTag(brandedTag);
  }
  return null;
};
