import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Container, SocialContainer, SocialLink, FacebookIcon, InstagramIcon, TwitterIcon } from './content.css';
import { ContentWrapper } from '../../../global.css';
import { contentfulToReactNative } from './content.helpers';
import PicksDetails from '../../picks/details';

const query = graphql`
  query ContentQuery {
    footerJson {
      instagramUrl
      facebookUrl
      twitterUrl
    }
  }
`;

export function ArticleContentComponent({ body }) {
  const data = useStaticQuery(query).footerJson;
  const [pickedBook, setPickedBook] = useState(null);

  return (
    <>
      <ContentWrapper>
        <Container>{renderRichText(body, contentfulToReactNative(setPickedBook))}</Container>
        <SocialContainer>
          <SocialLink href={data.instagramUrl} aria-label="Instagram">
            <InstagramIcon />
          </SocialLink>

          <SocialLink href={data.facebookUrl} aria-label="Facebook">
            <FacebookIcon />
          </SocialLink>

          <SocialLink href={data.twitterUrl} aria-label="Twitter">
            <TwitterIcon />
          </SocialLink>
        </SocialContainer>
      </ContentWrapper>
      <PicksDetails book={pickedBook} resetBook={() => setPickedBook(null)} />
    </>
  );
}
