import React from 'react';
import { MARKS, BLOCKS, INLINES } from '@contentful/rich-text-types';
const slugify = require('slugify');
import * as Styled from './content.css';

export const contentfulToReactNative = setBook => ({
  renderMark: {
    [MARKS.UNDERLINE]: function Underline(text) {
      return text;
    },
    [MARKS.BOLD]: function Bold(text) {
      return <Styled.BoldedText>{text}</Styled.BoldedText>;
    },
    [MARKS.ITALIC]: function Italic(text) {
      return <Styled.ItalicText>{text}</Styled.ItalicText>;
    },
  },
  renderNode: {
    [INLINES.HYPERLINK]: function Hyperlink({ data }, text) {
      return (
        <Styled.ExternalLink href={data.uri} rel="noopener noreferer" target="_blank">
          {text}
        </Styled.ExternalLink>
      );
    },
    [INLINES.ENTRY_HYPERLINK]: function EntryHyperlink({ data }, text) {
      const contentType = data.target.__typename;

      if (contentType === 'ContentfulArticle') {
        const slug = slugify(data.target.title, {
          lower: true,
          strict: true,
        });
        return <Styled.InternalLink to={`/article/${slug}`}>{text}</Styled.InternalLink>;
      }
      if (contentType === 'ContentfulBook') {
        return <Styled.InternalLinkButton onClick={() => setBook(data.target)}>{text}</Styled.InternalLinkButton>;
      }
      return null;
    },
    [INLINES.EMBEDDED_ENTRY]: function EmbeddedEntry(node) {
      return null;
    },
    [BLOCKS.PARAGRAPH]: function Paragraph(_node, children) {
      return <Styled.Text>{children}</Styled.Text>;
    },
    [BLOCKS.EMBEDDED_ASSET]: function EmbeddedAsset(node) {
      if (node?.data?.target?.fluid) {
        return <Styled.Image className="embedded-asset" fluid={node.data.target.fluid} alt={node.data.target.title} />;
      }
    },
    [BLOCKS.HEADING_1]: function Heading1(_node, children) {
      return <Styled.Heading1>{children}</Styled.Heading1>;
    },
    [BLOCKS.HEADING_2]: function Heading2(_node, children) {
      return <Styled.Heading2>{children}</Styled.Heading2>;
    },
    [BLOCKS.HEADING_3]: function Heading3(_node, children) {
      return <Styled.Heading3>{children}</Styled.Heading3>;
    },
    [BLOCKS.HEADING_4]: function Heading4(_node, children) {
      return <Styled.Heading4>{children}</Styled.Heading4>;
    },
    [BLOCKS.HEADING_5]: function Heading5(_node, children) {
      return <Styled.Heading5>{children}</Styled.Heading5>;
    },
    [BLOCKS.HEADING_6]: function Heading6(_node, children) {
      return <Styled.Heading6>{children}</Styled.Heading6>;
    },
    [BLOCKS.UL_LIST]: function UlList(_node, children) {
      return (
        <Styled.List>
          {children.map((child, index) => {
            return <Styled.ListElement key={index}>{child}</Styled.ListElement>;
          })}
        </Styled.List>
      );
    },
    [BLOCKS.OL_LIST]: function OlList(_node, children) {
      return children.map(child => {
        return child;
      });
    },
    [BLOCKS.LIST_ITEM]: function ListItem(_node, child) {
      return child;
    },
    [BLOCKS.QUOTE]: function Quote(_, children) {
      return <Styled.Quote>{children}</Styled.Quote>;
    },
    [BLOCKS.HR]: function Hr(_node, child) {
      return child;
    },
  },
});
