import React from 'react';
import { Container, Title, Subtitle, Date, SeparatorGroup, Separator, Label } from './hero.css';
import { getStyleForTag } from './hero.helpers';
import { formatDateArticle } from '../../../helpers/date';
import { ContentWrapper } from '../../../global.css';

export function ArticleHeroComponent({ title, subtitle, date, brandedTag, relatedTag }) {
  const tagStyles = getStyleForTag(brandedTag, relatedTag);
  return (
    <ContentWrapper>
      <Container>
        {tagStyles && (
          <Label color={tagStyles.color} light={tagStyles.light}>
            {tagStyles.label}
          </Label>
        )}
        <Title>{title}</Title>
        {subtitle && <Subtitle dangerouslySetInnerHTML={{ __html: subtitle.childMarkdownRemark.html }} />}

        {date && <Date>{formatDateArticle(date)}</Date>}
        <SeparatorGroup>
          <Separator />
          <Separator />
          <Separator />
        </SeparatorGroup>
      </Container>
    </ContentWrapper>
  );
}
