import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import { ArticleHero } from '../components/article/hero';
import { ArticleContent } from '../components/article/content';
import { ArticleRelated } from '../components/article/related';

export const query = graphql`
  query ArticleQuery($id: String!) {
    contentfulArticle(id: { eq: $id }) {
      id
      title
      subtitle {
        childMarkdownRemark {
          html
        }
      }
      body {
        raw
        references {
          ... on ContentfulArticle {
            id
            title
            __typename
            contentful_id
          }
          ... on ContentfulBook {
            id
            titleNode: title {
              title
            }
            author
            purchaseLinks {
              name
              url {
                url
              }
            }
            purchaseLinks {
              url {
                url
              }
              os
            }
            pickCollection
            pickDate
            season
            coverImage {
              title
              fixed(width: 173, height: 261) {
                width
                height
                src
                srcSet
                base64
                tracedSVG
                srcWebp
                srcSetWebp
              }
            }
            __typename
            contentful_id
          }
          ... on ContentfulVideo {
            id
          }
          ... on ContentfulAsset {
            id
            contentful_id
            title
            fluid(maxWidth: 306, quality: 100) {
              aspectRatio
              src
              srcSet
              sizes
              base64
              tracedSVG
              srcWebp
              srcSetWebp
            }
          }
        }
      }
      date
      brandedTag
      relatedTag
      relatedArticles {
        coverPhoto {
          fluid(maxWidth: 297, quality: 100) {
            aspectRatio
            src
            srcSet
            sizes
            base64
            srcWebp
            srcSetWebp
          }
        }
        title
        id
        brandedTag
        relatedTag
      }
    }
  }
`;

const Index = ({ data }) => {
  const { title, subtitle, date, body, brandedTag, relatedTag, relatedArticles } = data.contentfulArticle;

  return (
    <Layout>
      <ArticleHero title={title} subtitle={subtitle} date={date} brandedTag={brandedTag} relatedTag={relatedTag} />
      {body && <ArticleContent body={body} />}
      {relatedArticles?.length > 0 && <ArticleRelated relatedArticles={relatedArticles} />}
    </Layout>
  );
};

export default Index;
