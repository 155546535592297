import styled from 'styled-components';
import { color } from '../../../constants/theme';
import MEDIA from '../../../helpers/mediaTemplates';

export const Container = styled.div`
  margin-bottom: 20px;
  margin-top: 50px;
  ${MEDIA.MIN_TABLET`
  margin-top: 60px;
    margin-bottom: 56px;
  `}
`;

export const Title = styled.h2`
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  margin-bottom: 30px;
  ${MEDIA.MIN_TABLET`
    margin-bottom: 46px;
    font-size: 30px;
    line-height: 36px;
  `}
`;

export const Articles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${MEDIA.MIN_TABLET`
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
  `}
`;

export const Separator = styled.hr`
  border: none;
  border-top: 1px solid ${color.border};
  margin-bottom: 72px;
  ${MEDIA.MIN_TABLET`
    margin-bottom: 70px;
  `}
`;
