import styled from 'styled-components';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import FacebookSvg from '../../../images/facebook-small.svg';
import InstagramSvg from '../../../images/instagram-filled.svg';
import TwitterSvg from '../../../images/twitter.svg';
import { color, font } from '../../../constants/theme';
import MEDIA from '../../../helpers/mediaTemplates';

export const Container = styled.div`
  margin-top: 80px;
`;

export const Text = styled.p`
  font-size: 20px;
  line-height: 38px;
  margin-top: 30px;
  margin-bottom: 30px;
  white-space: pre-wrap;
  & > p {
    margin: 0;
  }
`;

export const BoldedText = styled.span`
  font-weight: bold;
`;

export const ItalicText = styled.span`
  font-style: italic;
`;

export const ExternalLink = styled.a`
  color: ${color.primaryCta};
`;

export const InternalLink = styled(Link)`
  color: ${color.primaryCta};
`;

export const InternalLinkButton = styled.button`
  background: transparent;
  border: 0;
  font-size: 20px;
  line-height: 38px;
  text-decoration: underline;
  padding: 0;
  cursor: pointer;
`;

export const List = styled.ul`
  list-style-type: disc;
`;

export const ListElement = styled.li`
  & > p {
    margin: 0;
  }
`;

export const Heading1 = styled.h1``;

export const Heading2 = styled.h2`
  font-size: 24px;
  line-height: 34px;
`;

export const Heading3 = styled.h3``;

export const Heading4 = styled.h4``;

export const Heading5 = styled.h5``;

export const Heading6 = styled.h6``;

export const Quote = styled.blockquote`
  & > * {
    font-size: 30px;
    line-height: 45px;
  }
  margin-top: 89px;
  margin-bottom: 68px;
`;

export const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  ${MEDIA.MIN_TABLET`
    margin-bottom: 60px;
  `};
`;

export const SocialLink = styled.a.attrs({
  target: '__blank',
  rel: 'noopener noreferrer',
})`
  display: inline-flex;
  width: 64px;
  height: 64px;
  background-color: ${color.secondaryBg};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin-right: 30px;

  ${MEDIA.MIN_TABLET`
    margin-right: 0;
    margin-left: 24px;
  `};
`;

export const FacebookIcon = styled(FacebookSvg)``;

export const InstagramIcon = styled(InstagramSvg)``;

export const TwitterIcon = styled(TwitterSvg)``;

export const Image = styled(Img)`
  width: 100%;

  ${MEDIA.MIN_TABLET`
    max-width: 306px;
    float: left;
    margin-right: 23px;
    margin-bottom: 30px;
    & + p + p {
      content: '';
      clear: both;
      display: table;
    }
  `}
`;
