import styled from 'styled-components';
import { color } from '../../../constants/theme';
import { TriangleTag } from '../../tag/index.css';
import MEDIA from '../../../helpers/mediaTemplates';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 118px;
  ${MEDIA.MIN_TABLET`
    margin-top: 232px;
  `}
`;

export const Title = styled.h1`
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  margin-top: 40px;
  ${MEDIA.MIN_TABLET`
    font-size: 72px;
    line-height: 72px;
    margin-top: 60px;
  `}
`;

export const Subtitle = styled.h5`
  font-size: 20px;
  line-height: 38px;
  text-align: center;
  margin-top: 20px;
`;

export const Date = styled.p`
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-top: 15px;
`;

export const SeparatorGroup = styled.div`
  margin-top: 68px;
  margin-bottom: -12px;
`;

export const Separator = styled.hr`
  border: none;
  border-top: 10px solid ${color.yellow};
  width: 100vw;
  margin: 12px 0;
`;

export const Label = styled(TriangleTag)`
  background: ${props => props.color};
  color: ${props => (props.light ? color.white : color.black)};
`;
